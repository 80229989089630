import VideoController from './video_controller'

const SELECTOR_VIDEO_WRAPPER = '.video-wrapper'
const DEFAULT_API_HOST = 'https://api.games.aol.com/'
const ID_GAME_IFRAME = 'game-inner-iframe'
const ID_IFRAME_CONTAINER = 'game-inner'
const SELECTOR_PRE_LOAD_ELEM = '.game-pre-load'
const CLASS_HIDE_GAME = 'm-game-frame--game-hidden'
const CLASS_HIDE_AD = 'm-game-frame--ad-hidden'
const LOCAL_STORAGE_VARIABLE_NAME = 'aolRecentlyPlayedGames'
const MAX_AMOUNT_OF_GAMES_IN_RECENTLY_PLAYED = 8
const SELECTER_POPUP_CONTAINER = '.game_g_a_popup'
const SELECTER_POPUP_CLOSE = '.game_g_a_popup--close'

const INTERSTITIAL_MESSAGES = {
  load: 'LOAD_INTERSTITIAL_AD',
  complete: 'INTERSTITIAL_AD_COMPLETE'
}

export default (() =>
  class GameFrame {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      this.gameVideoAdCompleteFlag = false
      this._addPublicInterface()

      if (!this.elem) return
      let gameData
      let gameOptions
      try {
        gameData = JSON.parse(this.elem.dataset.gameData)
        gameOptions = JSON.parse(this.elem.dataset.gameOptions)
      } catch (e) {
        this.gameVideoAdCompleteFlag = true
        throw new Error('Error Parsing Games Config')
      }

      this.isMobile = !!this.elem.dataset.ismobile
      this.gameData = {
        gameInfo: gameData,
        version: '2.0.1',
        apiHost: gameOptions.apiHost || DEFAULT_API_HOST,
        gameOptions: {
          pingTime: 300000,
          ...gameOptions
        }
      }

      this.adContainer = this.elem.querySelector(SELECTOR_PRE_LOAD_ELEM)
      this.closePopUpElement = document.querySelector(SELECTER_POPUP_CLOSE)
      if (this.closePopUpElement) {
        this.closePopUpElement.addEventListener('click', this._hideGoAwayAlert)
      }

      const videoControllerOptions = {
        wrapperElem: this.adContainer && this.adContainer.querySelector(SELECTOR_VIDEO_WRAPPER),
        onDestroyCb: this._videoAdDestroyed.bind(this)
      }
      this.videoController = new VideoController(videoControllerOptions)
      this.gameContainer = document.getElementById(ID_IFRAME_CONTAINER)

      this.preRollEnabled = true
      this.gameIframe = null

      window.wafer.ready(() => {
        this.init()
      })
    }

    // public method
    isGameVideoAdCompleted() {
      return this.gameVideoAdCompleteFlag
    }

    _addPublicInterface() {
      window.GameFrame = {
        isGameVideoAdCompleted: this.isGameVideoAdCompleted.bind(this)
      }
    }

    init() {
      // I don't know if this global object is needed, but it was there before so I am keeping it for the time being
      window.GAMES = this.gameData

      this.videoController.init()
      this._addMessageListener()
      if (this.isMobile) {
        this._updateParentContainerSize()
        this._updatePageTitle()
      }
      if (this.preRollEnabled) {
        this.playAd()
        this.gameVideoAdCompleteFlag = false
      } else {
        this.loadGame()
        this.gameVideoAdCompleteFlag = true
      }

      this._updateRecentlyPlayed()
    }

    playAd() {
      this._hideGameContainer()
      this._showAdContainer()
      this.videoController.playAd()
    }

    loadGame() {
      this._hideAdContainer()
      this._showGameContainer()
    }

    _addMessageListener() {
      window.addEventListener('message', event => {
        const { origin, data } = event

        const ALLOWED_ORIGINS = ['http://fun.games.com', 'https://fun.games.com']

        if (process.env.NODE_ENV === 'development') {
          // Anything in this if statement does not get compiled on prod or staging
          const { iframeURL } = this.gameData.gameInfo
          const url = new URL(iframeURL)
          ALLOWED_ORIGINS.push(url.origin)
        }

        if (!ALLOWED_ORIGINS.includes(origin)) {
          return
        }

        switch (data) {
          case INTERSTITIAL_MESSAGES.load:
            this.playAd()
            break
          default:
            // Do nothing
            break
        }
      })
    }

    _updatePageTitle() {
      const titleElem = document.head.getElementsByTagName('title')[0]
      if (titleElem) {
        titleElem.textContent = this.gameData.gameInfo.name
      }
    }

    _updateParentContainerSize() {
      // In order for the iframe to be 100% height all parents must be set to 100%
      let parentElem = this.elem.parentNode
      while (parentElem && parentElem.style) {
        parentElem.style.height = '100%'
        parentElem = parentElem.parentNode
      }
    }

    _hideGameContainer() {
      this.elem.classList.add(CLASS_HIDE_GAME)
    }

    _showGameContainer() {
      this.elem.classList.remove(CLASS_HIDE_GAME)
      if (!this.gameIframe) {
        this._createGameIframe()
      }
    }

    _showAdContainer() {
      this.elem.classList.remove(CLASS_HIDE_AD)
    }

    _hideAdContainer() {
      this.elem.classList.add(CLASS_HIDE_AD)
    }

    _createGameIframe() {
      if (this.gameIframe) return
      const { canvasWidth, canvasHeight, iframeURL } = this.gameData.gameInfo

      const iFrame = document.createElement('iframe')
      iFrame.src = iframeURL
      iFrame.id = ID_GAME_IFRAME
      if (!this.isMobile) {
        iFrame.style.maxWidth = `${canvasWidth}px`
        iFrame.style.maxHeight = `${canvasHeight}px`
      } else {
        iFrame.width = canvasWidth
        iFrame.height = canvasHeight
      }
      iFrame.allowFullscreen = true
      iFrame.msallowfullscreen = true
      iFrame.allow = 'fullscreen'

      this.gameIframe = iFrame
      this.gameContainer.appendChild(iFrame)
    }

    _videoAdDestroyed() {
      this.loadGame()
      this.gameVideoAdCompleteFlag = true
      if (this.gameIframe) {
        this.gameIframe.contentWindow.postMessage(INTERSTITIAL_MESSAGES.complete, '*')
      }
    }

    _updateRecentlyPlayed() {
      const { gameInfo: { id } = {} } = this.gameData
      const currentGame = { id }

      const storage = window.localStorage.getItem(LOCAL_STORAGE_VARIABLE_NAME)
      if (!storage) {
        window.localStorage.setItem(LOCAL_STORAGE_VARIABLE_NAME, JSON.stringify([currentGame]))
        return
      }

      let recentlyPlayedList = []
      try {
        recentlyPlayedList = JSON.parse(storage)
      } catch (e) {
        // If for some reason localStorage is corrupted make a new array with most recent game
        recentlyPlayedList = [currentGame]
      }

      // Remove game from localStorage if it exists as we want to move it to the front
      recentlyPlayedList = recentlyPlayedList.filter(ele => ele.id !== currentGame.id)

      // Keep it so there is only ever the number of games specified in recently played
      if (recentlyPlayedList.length >= MAX_AMOUNT_OF_GAMES_IN_RECENTLY_PLAYED) {
        recentlyPlayedList.splice(MAX_AMOUNT_OF_GAMES_IN_RECENTLY_PLAYED - 1)
      }

      recentlyPlayedList.unshift(currentGame)
      window.localStorage.setItem(LOCAL_STORAGE_VARIABLE_NAME, JSON.stringify(recentlyPlayedList))
    }

    _hideGoAwayAlert() {
      const popupElement = document.querySelector(SELECTER_POPUP_CONTAINER)
      if (popupElement) {
        popupElement.classList.add('hide')
      }
    }
  })()
